import { ContentFragment } from '@oneaudi/falcon-tools';
import { FeatureAppConfig } from '../FeatureHubAppDefinition';

export type FalconConfig = {
  __type?: 'aem-headless';
  config?: Partial<ContentFragment> & {
    fields: FeatureAppConfig;
  };
};

export type FootnoteFields = {
  Key: string;
  Text: string;
  position?: number;
};

export type Footnote = {
  fields: FootnoteFields;
};

export type ContentHeadless = {
  __type: string;
  fields: {
    Footnotes: {
      fields: {
        Footnotes: Footnote[];
      };
    };
  };
};

export function normalizeConfig(
  config?: FeatureAppConfig | FalconConfig
): FeatureAppConfig | undefined {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (config.__type === 'aem-headless') {
    const falconConfig = config as FalconConfig;
    return {
      disclaimerManagerScope:
        falconConfig.config?.fields.disclaimerManagerScope !== undefined
          ? falconConfig.config.fields.disclaimerManagerScope
          : 'DEFAULT',
      ...falconConfig.config?.fields
    };
  }
  return config as FeatureAppConfig;
}

export function normalizeContentHeadless(headless: ContentHeadless): FootnoteFields[] {
  const footnotes: FootnoteFields[] = [];
  headless.fields.Footnotes.fields.Footnotes.forEach((ftn) => {
    footnotes.push(ftn.fields);
  });
  return footnotes;
}
