import React, { useEffect, useState } from 'react';

import { ThemeProvider, TokenizedTheme, Loader, Text } from '@audi/audi-ui-react';

import { AudiFootnoteReferenceServiceInterfaceV3 } from '@oneaudi/footnote-reference-service';
import { AudiFootnoteServiceInterfaceV1 } from '@oneaudi/footnote-service';
import { ScopedDisclaimerManager } from '@volkswagen-onehub/disclaimer-manager';
import styled from 'styled-components';
import { DynamicFootnoteServiceV1 } from '@oneaudi/dynamic-footnote-service';
import { Footnote } from '../../types/footnotes-response.type';
import AudiFootnoteEngine from '../audi-footnote-engine/audi-footnote-engine';
import { FootnoteFields } from '../../utils/normalize-falcon-data';

export interface AudiFootnoteEngineContainerProps {
  apiUrls?: string[];
  audiUiTheme: TokenizedTheme;
  disclaimerManager: ScopedDisclaimerManager;
  disableBottomSpacing: boolean;
  disableSideSpacing: boolean;
  error?: Error;
  isServer?: boolean;
  layerElementClassName?: string;
  referenceServiceManager: AudiFootnoteReferenceServiceInterfaceV3;
  footnoteService?: AudiFootnoteServiceInterfaceV1;
  dynamicFootnoteService?: DynamicFootnoteServiceV1;
  featureAppId: string;
  footnoteData?: FootnoteFields[];
}

const LoadingWrapper = styled.div`
  text-align: center;
`;

const AudiFootnoteEngineContainer: React.FC<AudiFootnoteEngineContainerProps> = (props) => {
  const {
    apiUrls,
    audiUiTheme,
    disableBottomSpacing,
    disableSideSpacing,
    disclaimerManager,
    error,
    featureAppId,
    footnoteService,
    isServer,
    layerElementClassName,
    referenceServiceManager,
    footnoteData,
    dynamicFootnoteService
  } = props;
  const [componentError, setComponentError] = useState<Error>(error as Error);
  const [componentData, setComponentData] = useState<Footnote[] | null>(null);
  useEffect(() => {
    if (footnoteService) {
      try {
        footnoteService.registerCallback((footnotes) => {
          setComponentData(footnotes);
        });
      } catch (e /* istanbul ignore next */) {
        // eslint-disable-next-line no-console
        console.warn(e);
      }
    }

    return (): void => {
      if (footnoteService) {
        footnoteService.unregisterCallback();
      }
    };
  }, [footnoteService]);

  useEffect(() => {
    if (componentError === null && componentData === null && footnoteService) {
      if (apiUrls) {
        /* in headfull cms, footnotes should be retrieved on client side by using API url */
        (async (): Promise<void> => {
          footnoteService.init(apiUrls);
          const isInitialized = await footnoteService.isInitialized();

          if (isInitialized) {
            const data = footnoteService.getData();
            setComponentData(data);
          } else {
            const error_ = Error('[footnote-engine] Could not initialize footnote-service.');
            setComponentError(error_);
          }
        })();
      } else if (footnoteData !== undefined) {
        /* in headless cms, footnotes should be retrieved using content-service */
        try {
          footnoteService?.initWithFootnotes(footnoteData);
          const isInitialized = Promise.resolve(footnoteService.isInitialized());
          isInitialized.then((init) =>
            init === true
              ? setComponentData(footnoteService.getData())
              : setComponentError(
                  Error('[footnote-service] could not initialize footnote-service.')
                )
          );
        } catch (e) {
          // eslint-disable-next-line no-console
          console.warn('[fa-footnote-engine]', e);
          setComponentData(footnoteData);
        }
      }
    }
  }, [apiUrls, componentData, componentError, footnoteService, isServer, footnoteData]);

  if (componentError !== null) {
    // TODO style error and add error message
    return <div>Error</div>;
  }

  if (componentData === null || isServer) {
    return (
      <ThemeProvider theme={audiUiTheme}>
        <LoadingWrapper>
          <Loader
            variant="circular"
            size="small"
            monochrome
            displayValue="Loading"
            spaceStackStart="xl"
          />
          <Text spaceInlineStart="s">Loading..</Text>
        </LoadingWrapper>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={audiUiTheme}>
      <AudiFootnoteEngine
        disableBottomSpacing={disableBottomSpacing}
        disableSideSpacing={disableSideSpacing}
        disclaimerManager={disclaimerManager}
        featureAppId={featureAppId}
        footnotes={componentData}
        layerElementClassName={layerElementClassName}
        referenceServiceManager={referenceServiceManager}
        replaceFootnotes={footnoteService?.replaceFootNotes}
        dynamicFootnoteService={dynamicFootnoteService}
      />
    </ThemeProvider>
  );
};

export default AudiFootnoteEngineContainer;
