import * as React from 'react';
import { FeatureAppDefinition, FeatureServices } from '@feature-hub/core';
import { ReactFeatureApp } from '@feature-hub/react';
import type { Logger } from '@feature-hub/logger';
import { GfaServiceConfigProviderV1 } from '@volkswagen-onehub/gfa-service-config-provider';
import type { AsyncSsrManagerV1 } from '@feature-hub/async-ssr-manager';
import {
  DisclaimerManagerV1,
  ScopedDisclaimerManager
} from '@volkswagen-onehub/disclaimer-manager';
import type {
  AudiFootnoteReferenceServiceInterfaceV3,
  AudiFootnoteRefernceServiceScopeManagerInterfaceV3
} from '@oneaudi/footnote-reference-service';
import type { AudiFootnoteServiceInterfaceV1 } from '@oneaudi/footnote-service';
import { TokenizedTheme, audiDarkTheme, audiLightTheme } from '@audi/audi-ui-react';
import { ContentServiceV1 } from '@oneaudi/content-service';
import { DynamicFootnoteServiceV1 } from '@oneaudi/dynamic-footnote-service';
import FeatureApp from './FeatureApp';
import {
  ContentHeadless,
  normalizeConfig,
  normalizeContentHeadless
} from './utils/normalize-falcon-data';

export interface FeatureServiceDependencies extends FeatureServices {
  readonly 'gfa:service-config-provider': GfaServiceConfigProviderV1;
  readonly 's2:logger': Logger;
  readonly 's2:async-ssr-manager': AsyncSsrManagerV1;
  'disclaimer-manager': DisclaimerManagerV1;
  'audi-footnote-reference-service': AudiFootnoteRefernceServiceScopeManagerInterfaceV3;
  'audi-footnote-service': AudiFootnoteServiceInterfaceV1;
  'audi-dynamic-footnote-service': DynamicFootnoteServiceV1;
  readonly 'audi-content-service': ContentServiceV1 | undefined;
}

export type FeatureAppConfig = {
  apiUrls?: string[];
  disclaimerManagerScope?: string;
  layerElementClassName?: string;
  omitBottomSpacing?: string;
  omitSideSpacing?: string;
  uiTheme?: string;
};

type FeatureAppDefinitionType = FeatureAppDefinition<
  ReactFeatureApp,
  FeatureServiceDependencies,
  FeatureAppConfig
>;

function parseFeatureAppConfig(config: FeatureAppConfig | undefined): FeatureAppConfig {
  const apiUrls = config?.apiUrls;
  const uiTheme = config?.uiTheme ? config?.uiTheme : 'dark';
  const omitBottomSpacing = config?.omitBottomSpacing;
  const omitSideSpacing = config?.omitSideSpacing;
  const disclaimerManagerScope = config?.disclaimerManagerScope;
  const layerElementClassName = config?.layerElementClassName;

  return {
    apiUrls,
    disclaimerManagerScope,
    layerElementClassName,
    omitBottomSpacing,
    omitSideSpacing,
    uiTheme
  };
}

const featureAppDefinition: FeatureAppDefinitionType = {
  create: ({ featureServices, config: rawConfig, featureAppId }) => {
    const config = normalizeConfig(rawConfig);
    const loggerService = featureServices['s2:logger'];
    loggerService?.info('Feature App created.');
    const asyncSsrManager: AsyncSsrManagerV1 = featureServices['s2:async-ssr-manager'];
    const referenceServiceScopeManager: AudiFootnoteRefernceServiceScopeManagerInterfaceV3 =
      featureServices['audi-footnote-reference-service'];
    const logger: Logger = featureServices['s2:logger'];
    const footnoteService: AudiFootnoteServiceInterfaceV1 =
      featureServices['audi-footnote-service'];
    const disclaimerManager: DisclaimerManagerV1 = featureServices['disclaimer-manager'];
    const configProvider: GfaServiceConfigProviderV1 =
      featureServices['gfa:service-config-provider'];
    const contentService = featureServices['audi-content-service'];
    const dynamicFootnoteService = featureServices['audi-dynamic-footnote-service'];
    const contentHeadless: ContentHeadless = contentService?.getContent();
    const isOneCms = contentHeadless?.__type === 'aem-headless';

    const { disclaimerManagerScope, omitBottomSpacing, omitSideSpacing, uiTheme } =
      parseFeatureAppConfig(config);

    let scopedDisclaimerManager: ScopedDisclaimerManager;
    if (typeof disclaimerManagerScope !== 'undefined') {
      if (disclaimerManagerScope === 'DEFAULT') {
        scopedDisclaimerManager = disclaimerManager.getScopedDisclaimerManager();
      } else {
        scopedDisclaimerManager =
          disclaimerManager.getScopedDisclaimerManager(disclaimerManagerScope);
      }
    } else {
      scopedDisclaimerManager = disclaimerManager.getScopedDisclaimerManager(featureAppId);
    }

    let audiUiTheme: TokenizedTheme;
    if (uiTheme === 'light') {
      audiUiTheme = audiLightTheme;
    } else {
      audiUiTheme = audiDarkTheme;
    }

    const referenceServiceManager: AudiFootnoteReferenceServiceInterfaceV3 =
      referenceServiceScopeManager.getDefaultScopeRefService();
    const disableBottomSpacing: boolean = omitBottomSpacing === 'true';
    const disableSideSpacing: boolean = omitSideSpacing === 'true';

    const isServer = typeof asyncSsrManager !== 'undefined';

    if (isServer) {
      return {
        render: () => (
          <FeatureApp
            apiUrls={[]}
            audiUiTheme={audiUiTheme}
            disableBottomSpacing={disableBottomSpacing}
            disableSideSpacing={disableSideSpacing}
            disclaimerManager={scopedDisclaimerManager}
            error={null as unknown as undefined}
            featureAppId={featureAppId}
            footnoteService={footnoteService}
            isServer={isServer}
            referenceServiceManager={referenceServiceManager}
            configProvider={configProvider}
          />
        )
      };
    }

    try {
      const { apiUrls, layerElementClassName } = parseFeatureAppConfig(config);
      if ((!Array.isArray(apiUrls) || apiUrls.length === 0) && !isOneCms) {
        throw new Error(`[${featureAppId}] 'config.apiUrls' is not of type array or is empty.`);
      }
      return {
        render: () => (
          <FeatureApp
            apiUrls={apiUrls}
            audiUiTheme={audiUiTheme}
            disableBottomSpacing={disableBottomSpacing}
            disableSideSpacing={disableSideSpacing}
            disclaimerManager={scopedDisclaimerManager}
            error={null as unknown as undefined}
            isServer={isServer}
            featureAppId={featureAppId}
            footnoteService={footnoteService}
            dynamicFootnoteService={dynamicFootnoteService}
            layerElementClassName={layerElementClassName}
            referenceServiceManager={referenceServiceManager}
            configProvider={configProvider}
            footnoteData={isOneCms ? normalizeContentHeadless(contentHeadless) : undefined}
          />
        )
      };
    } catch (error) {
      logger.error(error);
      return {
        render: () => (
          <FeatureApp
            apiUrls={[]}
            audiUiTheme={audiUiTheme}
            disableBottomSpacing={disableBottomSpacing}
            disableSideSpacing={disableSideSpacing}
            disclaimerManager={scopedDisclaimerManager}
            error={error as Error}
            isServer={isServer}
            featureAppId={featureAppId}
            footnoteService={footnoteService}
            referenceServiceManager={referenceServiceManager}
            configProvider={configProvider}
          />
        )
      };
    }
  },
  dependencies: {
    featureServices: {
      'audi-content-service': '^1.0.0',
      'audi-footnote-reference-service': '^3.0.0',
      'audi-footnote-service': '^1.0.0',
      'audi-dynamic-footnote-service': '^1.0.0',
      'disclaimer-manager': '^1.4.0',
      's2:logger': '^1.0.0'
    }
  },
  optionalDependencies: {
    featureServices: {
      'audi-configurator-footnote-service': '^1.0.0',
      's2:async-ssr-manager': '^1.0.0',
      's2:serialized-state-manager': '^1.0.0'
    }
  }
};

export default featureAppDefinition;
