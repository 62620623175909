import Scrolling from './scrolling';
import { getRequiredOffset } from './helpers';

const averagePageSize = 4000; // px
const averagePageSizeScrollDuration = 660; // m

/**
 * calculateAnimationDuration - distance based dynamic animation length
 * @param {Number} startPosition_ - where it begins
 * @param {Number} targetPosition_ - where it ends
 * @returns {Number} scrollDuration - animation milliseconds
 */
function calculateAnimationDuration(startPosition_: number, targetPosition_: number): number {
  const delta = Math.abs(targetPosition_ - startPosition_);
  const scrollDuration = (averagePageSizeScrollDuration / averagePageSize) * delta;

  return scrollDuration;
}

function dynamicSmoothScrollTo(
  targetElement_: Element,
  context_: HTMLElement | null,
  startPosition_: number
): void {
  const scrollContext = Scrolling.getScrollContext(context_);
  let startTime: number | null = null;

  let animationPosition = 0;
  let currentTargetPosition = targetElement_.getBoundingClientRect().top;
  const scrollDuration = calculateAnimationDuration(startPosition_, currentTargetPosition);

  /**
   * easeOutQuad - quadratic function https://easings.net/de
   * @param {Number} numericInput_ - the number to be exponentially decreased
   * @returns {Number} numericOutput
   */
  function easeOutQuad(numericInput_: number): number {
    const numericOutput = numericInput_ * (2 - numericInput_);
    return numericOutput;
  }

  /**
   * calculateScrollDestination - calculates scrolling position
   * @param {HTMLElement} footnote_ - footnote element to scroll to
   * @param context_
   * @returns {Number} scroll position
   */
  // eslint-disable-next-line @typescript-eslint/no-shadow
  function calculateScrollDestination(footnote_: HTMLElement, context_: HTMLElement): number {
    const contextBoundingRectTop = context_.getBoundingClientRect().top;
    const footnoteBoundingRectTop = footnote_.getBoundingClientRect().top;
    const layerCorrection = context_ === document.body ? 0 : context_.scrollTop;

    return footnoteBoundingRectTop - contextBoundingRectTop + layerCorrection;
  }

  const animateScroll = (timestamp: number): void => {
    if (!startTime) {
      /* istanbul ignore next */
      startTime = timestamp || new Date().getTime();
    } // get id of animation

    const progress = (timestamp - startTime) / scrollDuration;
    const easeInPercentage = +easeOutQuad(progress).toFixed(2);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const updatedTargetPosition = calculateScrollDestination(targetElement_, context_);

    if (updatedTargetPosition && updatedTargetPosition > currentTargetPosition) {
      currentTargetPosition = updatedTargetPosition;
    }

    animationPosition = startPosition_ + currentTargetPosition * easeInPercentage;

    scrollContext?.scrollTo(0, animationPosition);

    const offsetFromTop = getRequiredOffset(targetElement_);
    if (
      progress > 1 ||
      (currentTargetPosition !== 0 && animationPosition >= currentTargetPosition - offsetFromTop) ||
      (currentTargetPosition === 0 && animationPosition <= 0) ||
      (updatedTargetPosition && updatedTargetPosition - startPosition_ <= 300)
    ) {
      cancelAnimationFrame(startTime);
      animationPosition = 0;
      scrollContext?.scrollTo(0, currentTargetPosition - offsetFromTop);
    } else {
      window.requestAnimationFrame(animateScroll);
    }
  };
  window.requestAnimationFrame(animateScroll);
}

const dynamicSmoothScroll = {
  dynamicSmoothScrollTo
};

export default dynamicSmoothScroll;
