import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  AudiFootnoteReferenceServiceInterfaceV3,
  EnumerableFootnote
} from '@oneaudi/footnote-reference-service';
import { Disclaimer, ScopedDisclaimerManager } from '@volkswagen-onehub/disclaimer-manager';
import delegate, { DelegateEvent } from 'delegate-it';
import { responsiveStyles } from '@audi/audi-ui-react';
import styled from 'styled-components';
import { DynamicFootnoteServiceV1 } from '@oneaudi/dynamic-footnote-service';
import {
  checkIsFefaInLayer,
  createHashForGivenString,
  createReferenceList,
  extractFootnoteId,
  filterArrayOfObjectsForKey,
  filterReferencesToAdd,
  findAllPropsInElement,
  findFootnoteReferencesOutsideOfFapps,
  getContext,
  getLayers,
  isLayerVisible,
  referenceSelector,
  removeDuplicates,
  replaceTextWithFootnoteNumber,
  setDataset,
  sortArrayIfValueIsUndefined,
  sortFootnotesForDisplay
} from '../../services/helpers';

import AudiFootnoteListItem from './audi-footnote-list-item';
import { Footnote, numberedreferencesFromFappsType } from '../../types/footnotes-response.type';
import { scrollToFootnote } from '../../services/scrolling';
import { useFootnoteUpdate } from '../../services/useFootnoteUpdateHook';

export type AudiFootnoteEngineProps = {
  disableBottomSpacing?: boolean;
  disableSideSpacing?: boolean;
  disclaimerManager: ScopedDisclaimerManager;
  featureAppId: string;
  footnotes: Footnote[] | null;
  layerElementClassName?: string;
  referenceServiceManager: AudiFootnoteReferenceServiceInterfaceV3;
  replaceFootnotes: ((footnotes: Footnote[]) => void) | undefined;
  dynamicFootnoteService?: DynamicFootnoteServiceV1;
};
export interface AudiFinalFootnotesToRenderInterface {
  footnoteIndex?: string;
  footnoteNumber?: number;
  Key: string;
  Text: string;
  contextID: string;
}

const FootnoteEngineStyledContainer = styled.div`
  --one-footer-bottom-spacing: var(${({ theme }): string => theme.responsive.spacing.xxl});
  --one-footer-side-spacing: 16px;
  background-color: var(${({ theme }): string => theme.colors.ui.inverted});
  display: block;
  position: relative;

  ${({ theme }): string =>
    responsiveStyles(
      {
        '--one-footer-side-spacing': {
          l: '60px',
          m: '40px',
          s: '28px',
          xl: '96px',
          xxl: 'calc((100% - 1728px) / 2)'
        }
      },
      theme.breakpoints
    )}
  /* stylelint-disable */
  padding:
    0
    ${(props: AudiFootnoteEngineProps): string =>
    props.disableSideSpacing === true ? '0px' : 'var(--one-footer-side-spacing)'}
    ${(props): string =>
    props.disableBottomSpacing === true ? '0px' : 'var(--one-footer-bottom-spacing)'};
  /* stylelint-enable */

  &:empty {
    padding-bottom: 0;
  }

  .sc-fn-sup,
  .sc-fn-index {
    cursor: pointer;
  }
`;

const FootnoteStyledList = styled.ul`
  box-sizing: border-box;
  list-style: none;
  margin: 0 auto;
  max-width: ${({ theme }): string => `${theme.breakpoints.xxl}px`};
  padding: 0;
  width: 100%;

  .nm-no-rate .nm-footnote-finance {
    display: none;
  }
`;

const AudiFootnoteEngine: React.FC<AudiFootnoteEngineProps> = (props) => {
  const {
    disableBottomSpacing,
    disableSideSpacing,
    disclaimerManager,
    featureAppId,
    footnotes,
    layerElementClassName,
    referenceServiceManager,
    replaceFootnotes,
    dynamicFootnoteService
  } = props;
  const [referencesFromDom, setReferencesFromDom] = useState<HTMLLinkElement[]>([]);

  const [referencesFromFapps, setReferencesFromFapps] = useState<EnumerableFootnote[]>([]);
  const [footnoteIDsToDisplay, setFootnoteIDsToDisplay] = useState<string[]>([]);
  const [filteredFootnotes, setFilteredFootnotes] = useState<Footnote[]>([]);
  const element = useRef<HTMLDivElement>(null);

  const [footnotesFromDisclaimerService, setfootnotesFromDisclaimerService] = useState<
    Disclaimer[]
  >([]);

  const [finalFootnotesToRender, setFinalFootnotesToRender] = useState<
    AudiFinalFootnotesToRenderInterface[]
  >([]);

  const [globalFootnotesFromDisclaimerService, setglobalFootnotesFromDisclaimerService] = useState<
    Disclaimer[]
  >([]);

  /**
   * This function finds footnotes within the DOM which are outside of 'feature-app' tags
   */
  const handleFootnotesUpdate = useCallback(
    (context_: HTMLElement | null): void => {
      const myContext = getContext(element.current, {
        layerElementClassName
      });
      const contains =
        context_ === null ? false : myContext?.contains(context_) || context_ === myContext;

      const domContext = contains ? context_ : myContext;
      if (domContext !== document.body) {
        // adds the scroll listener inside layer
        delegate(
          referenceSelector,
          'click',
          (event: DelegateEvent) => {
            scrollToFootnote(event, {
              layerElementClassName
            });
          },
          {
            base: domContext as HTMLElement
          }
        );
      }

      setReferencesFromDom((references) =>
        references.concat(
          Array.from(findFootnoteReferencesOutsideOfFapps(domContext as HTMLElement))
        )
      );
    },
    [setReferencesFromDom, layerElementClassName]
  );

  /**
   * This hook sets the event listeners for custom event and the scroll listener
   */
  useEffect((): (() => void) => {
    document.addEventListener('content:rendered', handleContentUpdate);
    document.addEventListener('PAGE_LOADED', handleContentUpdate);
    document.addEventListener('PAGE_READY', handleContentUpdate);
    document.addEventListener('LAYER_LOADED', handleContentUpdate);
    const controller = new AbortController();
    delegate(
      referenceSelector,
      'click',
      (event: DelegateEvent) => {
        scrollToFootnote(event, {
          layerElementClassName
        });
      },
      {
        signal: controller.signal
      }
    );
    return () => {
      document.removeEventListener('content:rendered', handleContentUpdate);
      document.removeEventListener('PAGE_LOADED', handleContentUpdate);
      document.removeEventListener('PAGE_READY', handleContentUpdate);
      document.removeEventListener('LAYER_LOADED', handleContentUpdate);
      controller.abort();
    };
    // eslint-disable-next-line
  }, []); // component did mount

  /**
   * This hook subscribes to the disclaimer-service
   */
  useEffect(() => {
    const { unsubscribe: unsubscribeFromDisclaimerService } = disclaimerManager.subscribe(() => {
      const { global, reference } = disclaimerManager.getFooterDisclaimers();
      setfootnotesFromDisclaimerService(reference);
      setglobalFootnotesFromDisclaimerService(global);
    });
    return (): void => {
      unsubscribeFromDisclaimerService();
    };
    // eslint-disable-next-line
  }, []);

  /**
   * Add & Remove footnotes from DisclaimerService
   */
  useEffect(() => {
    filteredFootnotes.forEach((footnote) => {
      disclaimerManager.registerReferenceDisclaimer(
        'footer-reference',
        `${footnote.Key}###KEYPREFIX###${footnote.Text}`
      );
    });
    return (): void => {
      filteredFootnotes.forEach((footnote) => {
        disclaimerManager.unregisterReferenceDisclaimer(
          'footer-reference',
          `${footnote.Key}###KEYPREFIX###${footnote.Text}`
        );
      });
    };
  }, [filteredFootnotes, disclaimerManager]);

  /**
   * This part brings together the disclaimer footnotes and the DOM + reference-service footnotes
   */
  useEffect(() => {
    let transFormedDisclaimerFootnotes: AudiFinalFootnotesToRenderInterface[] =
      footnotesFromDisclaimerService.map((footnote) => {
        const isInFilteredFootnotes = filteredFootnotes.find(
          (filteredFootnote) =>
            filteredFootnote.Key === footnote.text.replace(/###KEYPREFIX###(.*)([\n\r\s].*)*/gm, '')
        );
        const Key = isInFilteredFootnotes
          ? isInFilteredFootnotes.Key
          : createHashForGivenString(footnote.text.replace(/(.*)###KEYPREFIX###/, ''));

        const footnoteIndex = createReferenceList(footnote.namedReference, footnote.reference);

        return {
          Key,
          Text: footnote.text.replace(/(.*)###KEYPREFIX###/, ''),
          contextID: `${Key}-${featureAppId}`,
          footnoteIndex,
          footnoteNumber: footnote.reference ? parseInt(footnote.reference, 10) : undefined
        };
      });

    const sortArray = sortArrayIfValueIsUndefined('footnoteNumber');

    transFormedDisclaimerFootnotes = sortArray(
      transFormedDisclaimerFootnotes
    ) as AudiFinalFootnotesToRenderInterface[];

    globalFootnotesFromDisclaimerService.forEach((globalFootnote) => {
      const Key = createHashForGivenString(globalFootnote.text);
      transFormedDisclaimerFootnotes.push({
        Key,
        Text: globalFootnote.text,
        contextID: `${Key}-${featureAppId}`
      });
    });

    setFinalFootnotesToRender(transFormedDisclaimerFootnotes);
  }, [
    filteredFootnotes,
    footnotesFromDisclaimerService,
    globalFootnotesFromDisclaimerService,
    featureAppId
  ]);

  /**
   * this function listens to custom events and then executes the functions
   */
  const handleContentUpdate = useCallback(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (event_): void => {
      const context = event_.detail?.element || null;
      const currentFootnotes = event_.detail?.footnotes;

      if (typeof currentFootnotes !== 'undefined') {
        if (replaceFootnotes) {
          replaceFootnotes(currentFootnotes);
        }
      }

      const isFefaInLayer = checkIsFefaInLayer(element.current, {
        layerElementClassName
      });
      const isNeMoEvent =
        event_.type === 'content:rendered' ||
        event_.type === 'PAGE_LOADED' ||
        event_.type === 'PAGE_READY' ||
        event_.type === 'LAYER_LOADED';

      if (isFefaInLayer || !isNeMoEvent || !isLayerVisible()) {
        handleFootnotesUpdate(context);
      }
    },
    [handleFootnotesUpdate, replaceFootnotes, layerElementClassName]
  );

  const getNumberOfFootnoteReference = useCallback(
    (referenceID: string): number => {
      const id = referenceID;
      let number = -1;
      finalFootnotesToRender.forEach((footnote) => {
        if (footnote.Key === id && footnote.footnoteNumber) {
          number = footnote.footnoteNumber;
        }
      });
      return number;
    },
    [finalFootnotesToRender]
  );

  /**
   * Updates `footnoteIDsToDisplay` with references in `data_`.
   * Ignores `data_` if FEFA is placed in a layer
   * because `data_` may come from a feature app that is not part of the layer.
   * In this case it searches the DOM for references.
   */
  const processReferencesFromFapps = useCallback((data_: EnumerableFootnote[]): void => {
    setReferencesFromFapps(data_);

    setFootnoteIDsToDisplay((currentFootnoteIDsToDisplay) => {
      const footnoteIDsToDisplayFromFapps = filterReferencesToAdd(
        currentFootnoteIDsToDisplay,
        data_
      );

      return removeDuplicates(currentFootnoteIDsToDisplay.concat(footnoteIDsToDisplayFromFapps));
    });
  }, []);

  /**
   * Searches the DOM for 'feature-app' tags and '[data-fefa-custom-id]' attribute and collects the ids.
   * This is to determine which feature-app is relevant to this fefa and which is not.
   */
  const getFeatureAppList = useCallback(() => {
    const isFefaInLayer = checkIsFefaInLayer(element.current, {
      layerElementClassName
    });
    const context = getContext(element.current, {
      layerElementClassName
    });

    const filter = filterArrayOfObjectsForKey('fefaCustomId');

    const featureAppIdsInContext = findAllPropsInElement({
      element: context,
      prop: 'id',
      querySelector: 'feature-app'
    }) as string[];

    const featureAppCustomTags = findAllPropsInElement({
      element: context,
      prop: 'dataset',
      querySelector: '[data-fefa-custom-id]',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      filter
    }) as string[];

    const featureAppInContext = [...featureAppIdsInContext, ...featureAppCustomTags];

    if (isFefaInLayer) {
      return featureAppInContext;
    } // else

    const featureAppIdsInLayers: string[] = [];
    const layerElements = getLayers();

    layerElements.forEach((layerElement) => {
      const featureAppIdsInLayer = findAllPropsInElement({
        element: layerElement,
        prop: 'id',
        querySelector: 'feature-app'
      }) as string[];

      const featureAppCustomTagsLayer = findAllPropsInElement({
        element: layerElement,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        filter,
        prop: 'dataset',
        querySelector: '[data-fefa-custom-id]'
      }) as string[];

      featureAppIdsInLayers.push(...featureAppIdsInLayer, ...featureAppCustomTagsLayer);
    });

    return featureAppInContext.filter(
      (featureAppIdInContext) => !featureAppIdsInLayers.includes(featureAppIdInContext)
    );
  }, [layerElementClassName, element]);

  const receiveAllReferences = useCallback((): void => {
    if (referenceServiceManager !== null) {
      referenceServiceManager._registerOnUpdate(() => {
        const featureAppList = getFeatureAppList();
        const allReferences = referenceServiceManager._getReferences(featureAppList);

        processReferencesFromFapps(allReferences);
      });
    }
  }, [referenceServiceManager, processReferencesFromFapps, getFeatureAppList]);

  const sendNumberedReferences = useCallback(
    (numberedreferencesFromFapps_: numberedreferencesFromFappsType[]): void => {
      if (referenceServiceManager !== null) {
        if (numberedreferencesFromFapps_.length > 0) {
          const featureAppList = getFeatureAppList();
          referenceServiceManager._sendNumberedReferences(
            numberedreferencesFromFapps_,
            featureAppList
          );
        }
      }
    },
    [referenceServiceManager, getFeatureAppList]
  );

  const checkFootnoteExists = useCallback(
    (referenceID: string): boolean => !!footnotes?.find((footnote) => footnote.Key === referenceID),
    [footnotes]
  );

  useEffect(() => {
    receiveAllReferences();
    handleFootnotesUpdate(null);
    return (): void => {
      if (referenceServiceManager !== null) {
        referenceServiceManager._unregisterOnUpdate();
      }
    };
  }, [handleFootnotesUpdate, receiveAllReferences, referenceServiceManager]);

  useEffect(() => {
    if (referencesFromDom.length !== 0) {
      const filteredReferences: string[] = [];
      referencesFromDom.forEach((reference) => {
        const footnoteIDFromHref = extractFootnoteId(reference.href);
        const footnoteIDFromDataAttribute = reference.dataset.referenceId
          ? extractFootnoteId(reference.dataset.referenceId)
          : undefined;
        const footnoteID = footnoteIDFromDataAttribute || footnoteIDFromHref;

        if (checkFootnoteExists(footnoteID)) {
          if (!filteredReferences.includes(footnoteID)) {
            const isFootnoteReferenceInDOM = !!document.querySelector(
              `.audi-j-footnote-reference[href="#${footnoteIDFromHref}"]`
            );
            if (isFootnoteReferenceInDOM) {
              filteredReferences.push(footnoteID);
            }
          }
        }
      });

      const featureAppReferences = referencesFromFapps.map(({ id }) =>
        extractFootnoteId(id as string)
      );

      setFootnoteIDsToDisplay(removeDuplicates(filteredReferences.concat(featureAppReferences)));
    }
  }, [layerElementClassName, referencesFromDom, checkFootnoteExists, referencesFromFapps]);

  useEffect(() => {
    let filteredTemp: React.SetStateAction<Footnote[]> | undefined = [];

    footnotes?.forEach((footnote) => {
      const position = footnoteIDsToDisplay.indexOf(footnote.Key);
      if (position !== -1) {
        const _footnote = { ...footnote, position };
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        filteredTemp?.push(_footnote);
      }
    });
    filteredTemp = sortFootnotesForDisplay(filteredTemp);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setFilteredFootnotes(filteredTemp);
  }, [footnoteIDsToDisplay, footnotes]);

  useEffect(() => {
    if (finalFootnotesToRender.length !== 0) {
      referencesFromDom.forEach((reference) => {
        const footnoteIDFromHref = extractFootnoteId(reference.href);
        const footnoteIDFromDataAttribute = reference.dataset.referenceId
          ? extractFootnoteId(reference.dataset.referenceId)
          : undefined;
        const referenceID = footnoteIDFromDataAttribute || footnoteIDFromHref;
        const number: number = getNumberOfFootnoteReference(referenceID);
        replaceTextWithFootnoteNumber(reference, number);
        setDataset({
          element_: reference as HTMLLinkElement,
          id: `${referenceID}-${featureAppId}`,
          name: 'referenceId',
          referenceId: referenceID
        });
      });

      const numberedreferencesFromFapps = referencesFromFapps.map((referenceObject) => {
        const referenceID = extractFootnoteId(referenceObject.id as string);
        const number: number = getNumberOfFootnoteReference(referenceID);
        return {
          id: referenceObject.id,
          number
        };
      });

      sendNumberedReferences(numberedreferencesFromFapps);
    }
  }, [
    finalFootnotesToRender,
    getNumberOfFootnoteReference,
    referencesFromDom,
    referenceServiceManager,
    referencesFromFapps,
    sendNumberedReferences,
    featureAppId
  ]);

  const [renderedFootnotes, setRenderedFootnotes] = useState<AudiFinalFootnotesToRenderInterface[]>(
    []
  );

  useFootnoteUpdate(() => {
    setRenderedFootnotes(finalFootnotesToRender);
  }, finalFootnotesToRender);

  useEffect(() => {
    try {
      dynamicFootnoteService?.analyseRenderedFootnotes(renderedFootnotes);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn('fa-footnote-engine', e);
    }
  }, [renderedFootnotes]);

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <FootnoteEngineStyledContainer
      className="audi-footnote-engine"
      disableBottomSpacing={disableBottomSpacing}
      disableSideSpacing={disableSideSpacing}
      ref={element}
    >
      {renderedFootnotes.length > 0 && (
        // @TODO: verify this is still needed for context-dependent
        // styling from outside
        <FootnoteStyledList>
          {renderedFootnotes.map((footnoteItem) => {
            const key = footnoteItem.Key + footnoteItem.Text;
            return (
              <AudiFootnoteListItem
                footnoteIndex={footnoteItem.footnoteIndex}
                footnoteItem={footnoteItem}
                key={key}
              />
            );
          })}
        </FootnoteStyledList>
      )}
    </FootnoteEngineStyledContainer>
  );
};

export default AudiFootnoteEngine;
