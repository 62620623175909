import { useEffect, useRef } from 'react';
import { AudiFinalFootnotesToRenderInterface } from '../components/audi-footnote-engine/audi-footnote-engine';

export const useFootnoteUpdate = (
  effect: () => void,
  footnotes: AudiFinalFootnotesToRenderInterface[]
) => {
  const equals = (
    prevDeps: AudiFinalFootnotesToRenderInterface[],
    deps: AudiFinalFootnotesToRenderInterface[]
  ) =>
    prevDeps.length === deps.length &&
    prevDeps.every((prevDep, index) => {
      return (
        prevDep.Text === deps[index].Text &&
        prevDep.footnoteIndex === deps[index].footnoteIndex &&
        prevDep.contextID === deps[index].contextID &&
        prevDep.Key === deps[index].Key &&
        prevDep.footnoteNumber === deps[index].footnoteNumber
      );
    });

  const prevFootnotes = useRef<AudiFinalFootnotesToRenderInterface[]>([]);

  useEffect(() => {
    if (!equals(prevFootnotes.current, footnotes)) {
      effect();
      prevFootnotes.current = footnotes;
    }
  }, [footnotes]);
};
