/**
 * This is the starting point of your application.
 * Feature Hub Apps will use this file to bootstrap the app.
 */
import * as React from 'react';
import { GfaServiceConfigProviderV1 } from '@volkswagen-onehub/gfa-service-config-provider';
import AudiFootnoteEngineContainer, {
  AudiFootnoteEngineContainerProps
} from './components/audi-footnote-engine-container/audi-footnote-engine-container';

interface FeatureAppProps extends AudiFootnoteEngineContainerProps {
  configProvider?: GfaServiceConfigProviderV1;
}

const FeatureApp: React.FC<FeatureAppProps> = ({
  apiUrls,
  audiUiTheme,
  disableBottomSpacing,
  disableSideSpacing,
  disclaimerManager,
  error,
  featureAppId,
  isServer,
  referenceServiceManager,
  footnoteService,
  layerElementClassName,
  footnoteData,
  dynamicFootnoteService
}: AudiFootnoteEngineContainerProps) => {
  return (
    <AudiFootnoteEngineContainer
      apiUrls={apiUrls}
      audiUiTheme={audiUiTheme}
      disableBottomSpacing={disableBottomSpacing}
      disableSideSpacing={disableSideSpacing}
      disclaimerManager={disclaimerManager}
      error={error}
      featureAppId={featureAppId}
      isServer={isServer}
      referenceServiceManager={referenceServiceManager}
      footnoteService={footnoteService}
      layerElementClassName={layerElementClassName}
      footnoteData={footnoteData}
      dynamicFootnoteService={dynamicFootnoteService}
    />
  );
};

export default FeatureApp;
