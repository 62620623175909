import { DelegateEvent } from 'delegate-it';
import dynamicSmoothScroll from './dynamicSmoothScrollTo';
import { referenceSelector } from './helpers';
import Scrolling from './scrolling';

/**
 * handleFootnoteReferenceClick - scrolls smoothly to related footnote
 * @param {Event} event_ - click event
 * @param {HTMLElement} context_
 * @returns {void}
 */
export const handleFootnoteReferenceClick = (
  event_: DelegateEvent,
  context_: HTMLElement | null
): void => {
  event_.preventDefault();
  const target = event_.delegateTarget as HTMLElement;
  /* istanbul ignore next */
  const reference: HTMLElement | null =
    target?.hasAttribute('class') && target?.getAttribute('class') === referenceSelector
      ? target
      : target?.closest(referenceSelector);

  const footnote = Scrolling.getFootnoteByReference(reference as HTMLElement, context_);
  if (footnote) {
    const startingPosition = Scrolling.getStartPosition(event_, context_);

    dynamicSmoothScroll.dynamicSmoothScrollTo(footnote, context_, startingPosition);
  }
};
