import { DelegateEvent } from 'delegate-it';
import { getContext, isLayerVisible, LayerElementOptions } from './helpers';

import { handleFootnoteReferenceClick } from './handleFootnoteReferenceClick';

interface DelegateMouseEventInterface extends DelegateEvent {
  pageY?: number;
}

const averagePageSize = 4000; // px
const averagePageSizeScrollDuration = 660; // milliseconds timingXXL every 4000px

/**
 * scrollToFootnote - retrieves the right context
 * for the clicked footnote and calls scroll handler
 * @param {Event} event_ - click event
 * @param {Object} layerElementClassName - passed through options
 * @returns {void}
 */
export function scrollToFootnote(
  event_: DelegateEvent,
  layerElementClassName?: LayerElementOptions
): void {
  const element = event_.delegateTarget as HTMLElement;
  const context = getContext(element, layerElementClassName);

  /* Check for Layer Visibilty to stop double event binding */
  if (!(isLayerVisible() && context === document.body)) {
    handleFootnoteReferenceClick(event_, context);
  }
}

/**
 * getStartPosition - where to start from
 * @param {Event} event_ - click event
 * @param {HTMLElement} context_ - click context
 * @returns {HTMLElement}
 */
function getStartPosition(event_: DelegateEvent, context_: HTMLElement | null): number {
  const event = event_ as DelegateMouseEventInterface;
  /* istanbul ignore next */
  const clickPosition = typeof event.pageY !== 'undefined' ? event.pageY : 0;
  const startingPosition =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line no-unsafe-optional-chaining
    context_ === document.body ? clickPosition : clickPosition + context_?.scrollTop;
  return startingPosition;
}

/**
 * easeOutQuad - quadratic function https://easings.net/de
 * @param {Number} numericInput_ - the number to be exponentially decreased
 * @returns {Number} numericOutput
 */
function easeOutQuad(numericInput_: number): number {
  const numericOutput = numericInput_ * (2 - numericInput_);
  return numericOutput;
}

/**
 * calculateAnimationDuration - distance based dynamic animation length
 * @param {Number} startPosition_ - where it begins
 * @param {Number} targetPosition_ - where it ends
 * @returns {Number} scrollDuration - animation milliseconds
 */
export function calculateAnimationDuration(
  startPosition_: number,
  targetPosition_: number
): number {
  const delta = Math.abs(targetPosition_ - startPosition_);
  const scrollDuration = (averagePageSizeScrollDuration / averagePageSize) * delta;

  return scrollDuration;
}

/**
 * getScrollContext - which context is going to be scrolled
 * @param {HTMLElement} context_ - click event
 * @returns {HTMLElement}
 */
function getScrollContext(context_: HTMLElement | null): Window | HTMLElement | null {
  return context_ === document.body ? window : getDataScroll(context_); // browser independent scroll context
}

function getDataScroll(context_: HTMLElement | null): HTMLElement | null {
  if (context_?.closest('div[data-scroll-context="true"]')) {
    return context_.closest('div[data-scroll-context="true"]');
  }
  return context_;
}
/**
 * calculateScrollDestination - calculates scrolling position
 * @param {HTMLElement} footnote_ - footnote element to scroll to
 * @returns {Number} scroll position
 */
function calculateScrollDestination(footnote_: HTMLElement, context_: HTMLElement): number {
  const contextBoundingRectTop = context_.getBoundingClientRect().top;
  const footonteBoundingRectTop = footnote_.getBoundingClientRect().top;
  /* istanbul ignore next */
  const layerCorrection = context_ === document.body ? 0 : context_.scrollTop;

  return footonteBoundingRectTop - contextBoundingRectTop + layerCorrection;
}

/**
 * getFootnoteByReference - find footnote for clicked reference
 * @param {HTMLElement} element_ - clicked footnote reference
 * @param {HTMLElement} context_
 * @returns {HTMLElement} footnote element
 */
function getFootnoteByReference(
  element_: HTMLElement,
  context_: HTMLElement | null
): Element | null {
  if (!element_ || !element_.getAttribute('href')) {
    return null;
  }
  const href = element_.getAttribute('href');
  return context_ ? context_.querySelector(href || '') : document.querySelector(href || '');
}

const exportFunctions = {
  calculateAnimationDuration,
  calculateScrollDestination,
  easeOutQuad,
  getDataScroll,
  getFootnoteByReference,
  getScrollContext,
  getStartPosition,
  handleFootnoteReferenceClick,
  scrollToFootnote
};

export default exportFunctions;
